"use client";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import postService from "./postService";

import {
  CustomError,
  FilterPostPayload,
  PostData,
  PostFormData,
  PostStatusUgrade,
  postLikeEmoji,
} from "./postInterfaces";

import { toast } from "react-toastify";

const initialState = {
  post: {} as PostData,
  posts: [] as PostData[],
  filteredposts: [] as PostData[],
  archivedPost: 0,
  draftedPost: 0,
  publishedPost: 0,
  postReactions: 0,
  isErrorPost: false,
  isSuccessPost: false,
  isLoadingPost: false,
  isLoadingReact_to_Post: false,
  message: "" as string,
  // pergination
  totalPosts: 0,
  currentPage: 1,
  totalPages: 1,
};

// *****************  *****************  //
// GET A SINGLE POST BY ID FROM POPULAR POSTS || SIGNED OUT USER
export const viewLandingPagePost = createAsyncThunk(
  "post/getlandingpage-PostById",
  async (postID: string, thunkAPI) => {
    try {
      const response = await postService.viewLandingPagePost(postID);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET POPULAR POSTS TO THE LANDING PAGE
export const getLandingPagePopularPosts = createAsyncThunk(
  "post/getallpopularposts",
  async (_, thunkAPI) => {
    try {
      const response = await postService.getLandingPagePopularPost();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL ARTICLES WITHOUT SIGNING IN
export const getAllArticles = createAsyncThunk(
  "post/getallmetaarticles",
  async (_, thunkAPI) => {
    try {
      const response = await postService.getAllArticles();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SIGNED IN USER || GET ALL PUBLISHED POST TO VIEWERS || WITH PAGINATION
export const GetAllViewablePostsData = createAsyncThunk(
  "post/getAllViewablePostsData",
  async (_, thunkAPI) => {
    try {
      const response = await postService.getAllViewablePosts();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// VIEW A SINGLE POST BY ID
export const viewMainPageBlog = createAsyncThunk(
  "post/getPostById",
  async (postID: string, thunkAPI) => {
    try {
      const response = await postService.viewASinglePost(postID);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// REACT TO A POST
export const reactToPost = createAsyncThunk(
  "post/react-to-post",
  async (payload: { postID: string; emoji: postLikeEmoji }, thunkAPI) => {
    try {
      const { postID, emoji } = payload;
      const response = await postService.reactToPost(postID, emoji);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// INCREMENT SHARE COUNT || SHARE POST
export const ShareArticle = createAsyncThunk(
  "post/share-article",
  async (postID: string, thunkAPI) => {
    try {
      const response = await postService.shareArticle(postID);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// *****************  *****************  //
// ADMIN PANEL || // GET ALL POST TO ADMIN DATABASE
export const getAllPostsAdminDashboard = createAsyncThunk(
  "post/getallposts-to-admin-dashboard",
  async (_, thunkAPI) => {
    try {
      const response = await postService.getAllPostDataAdmin();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN PANEL || UPDATE POST STATUS
export const changePostStatus = createAsyncThunk(
  "post/update-post-status",
  async (postData: PostStatusUgrade, thunkAPI) => {
    try {
      const response = await postService.updatePostStatus(postData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN-AUTHOR PANEL || CREATE NEW POST
export const createNewPost = createAsyncThunk(
  "post/create-new-post",
  async (PostInfo: PostFormData, thunkAPI) => {
    try {
      const response = await postService.createPost(PostInfo);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN PANEL || EDIT POST
export const editPost = createAsyncThunk(
  "post/edit-post",
  async (payload: { PostInfo: PostFormData; postID: string }, thunkAPI) => {
    try {
      const { PostInfo, postID } = payload;
      const response = await postService.editPost(postID, PostInfo);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ADMIN PANEL || DELETE USER
export const deletePostByAdmin = createAsyncThunk(
  "post/admin-delete-post",
  async (postId: string, thunkAPI) => {
    try {
      const response = await postService.deletePostByAdmin(postId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ************* SLICES ************* //
// ************* SLICES ************* //
// ************* SLICES ************* //
const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    RESET(state) {
      state.isErrorPost = false;
      state.isSuccessPost = false;
      state.isLoadingPost = false;
      state.message = "";
    },

    CALC_DRAFTED_POSTS(state) {
      const array: string[] = state.posts.map((post) => post.postStatus);
      let count = 0;
      array.forEach((item) => {
        // in the array we have several roles
        if (item === "Draft") {
          count += 1;
        }
      });
      state.draftedPost = count;
    },

    CALC_ARCHIVED_POSTS(state) {
      const array: string[] = state.posts.map((post) => post.postStatus);
      let count = 0;
      array.forEach((item) => {
        // in the array we have several roles
        if (item === "Archived") {
          count += 1;
        }
      });
      state.archivedPost = count;
    },

    CALC_PUBLISHED_POSTS(state) {
      const array: string[] = state.posts.map((post) => post.postStatus);
      let count = 0;
      array.forEach((item) => {
        // in the array we have several roles
        if (item === "Published") {
          count += 1;
        }
      });
      state.publishedPost = count;
    },

    FILTER_POSTS(state, action: PayloadAction<FilterPostPayload>) {
      const { publishedPosts, searchWord } = action.payload;
      const filterr = publishedPosts.filter(
        (post) =>
          post.title.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.authorName.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.postStatus.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.postCategories
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase()) ||
          post.topicTags
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase())
      );
      state.filteredposts = filterr;
    },
  },

  // *********** ************ //
  extraReducers: (builder) => {
    builder
      // GET ALL PUBLISHED POST TO VIEWERS
      .addCase(GetAllViewablePostsData.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(GetAllViewablePostsData.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.posts = action.payload.posts;
        state.totalPosts = action.payload.totalPosts;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(GetAllViewablePostsData.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        // toast.error(action.payload as string);
      })

      // GET ALL ARTICLES WITHOUT SIGNING IN
      .addCase(getAllArticles.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(getAllArticles.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.posts = action.payload.posts;
      })
      .addCase(getAllArticles.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
      })

      // GET POPULAR POSTS TO THE LANDING PAGE
      .addCase(getLandingPagePopularPosts.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(getLandingPagePopularPosts.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.posts = action.payload.popularPosts;
      })
      .addCase(getLandingPagePopularPosts.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // GET A SINGLE POST BY ID
      .addCase(viewMainPageBlog.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(viewMainPageBlog.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.post = action.payload.post;
        state.message = action.payload.message as string;
      })
      .addCase(viewMainPageBlog.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  VIEW A SINGLE POST BY ID FOR LANDING PAGE POST
      .addCase(viewLandingPagePost.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(viewLandingPagePost.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.post = action.payload.post;
      })
      .addCase(viewLandingPagePost.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // REACT TO A POST
      .addCase(reactToPost.pending, () => {
        // state.isLoadingReact_to_Post = true;
      })
      .addCase(reactToPost.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingReact_to_Post = false;
        state.postReactions = action.payload.likeCount;
        state.post = action.payload.updatedPost;
        // toast.success('Successful');
      })
      .addCase(reactToPost.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingReact_to_Post = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // INCREMENT SHARE COUNT || SHARE POST
      .addCase(ShareArticle.pending, () => {
        // state.isLoadingPost = true;
      })
      .addCase(ShareArticle.fulfilled, (state) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
      })
      .addCase(ShareArticle.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN || GET ALL USERS
      .addCase(getAllPostsAdminDashboard.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(getAllPostsAdminDashboard.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.posts = action.payload.posts;
      })
      .addCase(getAllPostsAdminDashboard.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN PANEL || // UPDATE POST STATUS
      .addCase(changePostStatus.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(changePostStatus.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(changePostStatus.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN-AUTHOR PANEL ||  CREATE NEW POST
      .addCase(createNewPost.pending, (state) => {
        state.isLoadingPost = true;
        state.isSuccessPost = false;
      })
      .addCase(createNewPost.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.post = action.payload.post;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(createNewPost.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isSuccessPost = false;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN PANEL || EDIT POST
      .addCase(editPost.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(editPost.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.message = action.payload.message;
        toast.success("Edited Successfully");
      })
      .addCase(editPost.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // ADMIN PANEL || DELETE USER
      .addCase(deletePostByAdmin.pending, (state) => {
        state.isLoadingPost = true;
      })
      .addCase(deletePostByAdmin.fulfilled, (state, action) => {
        state.isSuccessPost = true;
        state.isLoadingPost = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(deletePostByAdmin.rejected, (state, action) => {
        state.isErrorPost = true;
        state.isLoadingPost = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      });
  },
});

export const {
  RESET,
  CALC_ARCHIVED_POSTS,
  CALC_DRAFTED_POSTS,
  CALC_PUBLISHED_POSTS,
  FILTER_POSTS,
} = postSlice.actions;

export default postSlice.reducer;
