// Import the configured Axios instance
import axios from "../../../axios";
import {
  PostFormData,
  PostStatusUgrade,
  postLikeEmoji,
} from "./postInterfaces";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const POST_API_URL = `${backendUrl}/api/post`;

// SIGNED OUT USER
// GET POPULAR POSTS TO THE LANDING PAGE
const getLandingPagePopularPost = async () => {
  const response = await axios.get(POST_API_URL + "/getallpopularposts");
  return response.data;
};

// GET ALL ARTICLES WITHOUT SIGNING IN
const getAllArticles = async () => {
  const response = await axios.get(
    `${POST_API_URL}/getallpostsformetadata`
  );
  return response.data;
};

//  VIEW A SINGLE POST-BY-ID || LANDING PAGE
const viewLandingPagePost = async (postID: string) => {
  const response = await axios.get(
    POST_API_URL + "/getpostbyid/landingpage/" + postID
  );
  return response.data;
};

// SIGNED IN USER || GET ALL POST TO THE USER
// FUNCTION TO FETCH ALL VIEWABLE POSTS WITH PAGINATION
const getAllViewablePosts = async () => {
  const response = await axios.get(
    `${POST_API_URL}/getallposts`
  );
  return response.data;
};

//  VIEW A SINGLE POST-BY-ID || MAIN BLOG PAGE || SIGN IN USER
const viewASinglePost = async (postID: string) => {
  const response = await axios.get(
    POST_API_URL + "/getpostbyid/mainpage/" + postID
  );
  return response.data;
};

// REACT TO A POST
const reactToPost = async (postID: string, emoji: postLikeEmoji) => {
  const response = await axios.patch(
    POST_API_URL + "/reactions/reacttopost/" + postID,
    emoji
  );
  return response.data;
};

// INCREMENT SHARE COUNT || SHARE POST
const shareArticle = async (postID: string) => {
  const response = await axios.patch(
    POST_API_URL + "/share-articles/" + postID
  );
  return response.data;
};

// *****************  *****************  //
// ADMIN PANEL - ADMIN PAGE // GET ALL POST  TO ADMIN DATABASE
const getAllPostDataAdmin = async () => {
  const response = await axios.get(POST_API_URL + "/admingetallposts");
  return response.data;
};

// ADMIN PANEL || CREATE POST
const createPost = async (PostInfo: PostFormData) => {
  const response = await axios.post(POST_API_URL + "/createpost", PostInfo);
  return response.data;
};

// ADMIN-AUTHOR PANEL || CREATE A NEW POST
const editPost = async (postID: string, PostInfo: PostFormData) => {
  const response = await axios.patch(
    POST_API_URL + "/editpost/" + postID,
    PostInfo
  );
  return response.data;
};

// ADMIN PANEL ||  UPDATE POST STATUS
const updatePostStatus = async (postData: PostStatusUgrade) => {
  const response = await axios.patch(
    POST_API_URL + "/updatepoststatus",
    postData
  );
  return response.data;
};

// ADMIN PANEL || DELETE USER BY ADMINS OR AUTHOR OF THE POST
const deletePostByAdmin = async (postID: string) => {
  const response = await axios.delete(
    POST_API_URL + "/admindeletepost/" + postID
  );
  return response.data;
};

//  ***********  EXPORTING ***********  //
const postServices = {
  getAllPostDataAdmin,
  getLandingPagePopularPost,
  getAllArticles,
  viewASinglePost,
  viewLandingPagePost,
  reactToPost,
  getAllViewablePosts,
  createPost,
  editPost,
  shareArticle,
  updatePostStatus,
  deletePostByAdmin,
};

export default postServices;
