// Import the configured Axios instance
import axios from "../../../axios";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const API_URL = `${backendUrl}/api/`;

export interface emailData {
  subject: string;
  send_to: string;
  reply_to: string;
  template: string;
  url: string;
}

// SEND AUTOMATED EMAIL
const sendAutomatedEmail = async (emailData: emailData) => {
  const response = await axios.post(
    API_URL + "sendAutomatedEmail",
    emailData
  );
  return response.data;
};

//  ***********  EXPORTING ***********  //
const emailServices = {
  sendAutomatedEmail,
};

export default emailServices;
