// Import the configured Axios instance
import axios from "../../../axios";
import {
  CreateCommentFormData,
  CreateNestedCommentFormData,
  EditCommentFormData,
} from "./commentInterface";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const COMMENT_API_URL = `${backendUrl}/api/comments`;

// CREATE NEW POST
const createComment = async (CommentInfo: CreateCommentFormData) => {
  const response = await axios.post(
    COMMENT_API_URL + "/create=new=comment",
    CommentInfo
  );
  return response.data;
};

const getCommentsForAPost = async (postId: string) => {
  const response = await axios.get(
    COMMENT_API_URL + "/" + postId + "/fetchcomments"
  );
  return response.data;
};

const createNestedComments = async (
  CommentInfo: CreateNestedCommentFormData
) => {
  const response = await axios.post(
    COMMENT_API_URL + "/commentreply/nested==comment/",
    CommentInfo
  );
  return response.data;
};

// REACT TO A POST
const reactToComment = async (emoji: string, commentId: string) => {
  const response = await axios.patch(
    COMMENT_API_URL + "/reactions/==comment=/reacttocomment/",
    { emoji, commentId }
  );
  return response.data;
};

// REACT TO A POST
const editMyComment = async (
  CommentInfo: EditCommentFormData,
  commentId: string
) => {
  const response = await axios.patch(
    COMMENT_API_URL + "/" + commentId + "/edit---comment",
    CommentInfo
  );
  return response.data;
};

// DELETE COMMENT BY THE COMMENT WRITTER OR AN ADMIN
const deleteMyComment = async (commentId: string) => {
  const response = await axios.delete(
    COMMENT_API_URL + "/" + commentId + "/delete---comment"
  );
  return response.data;
};

// REPORT A USER FROM COMMENT
const reportUserComment =  async (commentId: string) => {
  const response = await axios.post(
    COMMENT_API_URL + "/" + commentId + "/report---user"
  );
  return response.data;
};

// GET ALL COMMENTS TO ADMIN DASHBOARD
const fetchAllComments = async () => {
  const response = await axios.get(
    COMMENT_API_URL + "/get--all--comments"
  );
  return response.data;
};

//  ***********  EXPORTING ***********  //
const commentServices = {
  createComment,
  getCommentsForAPost,
  createNestedComments,
  reactToComment,
  editMyComment,
  deleteMyComment,
  reportUserComment,
  fetchAllComments
};

export default commentServices;
