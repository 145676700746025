"use client";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import commentServices from "./commentService";

import { toast } from "react-toastify";
import {
  CustomError,
  CommentData,
  CreateCommentFormData,
  CreateNestedCommentFormData,
  EditCommentFormData,
  FilterCommentPayload,
} from "./commentInterface";

const initialState = {
  isErrorComment: false,
  isSuccessComment: false,
  isLoadingComment: false,
  isLoadingCreate_Comment: false,
  isLoadingReact_to_Comment: false,
  isLoadingEdit_Comment: false,
  message: "" as string,
  comment: {} as CommentData,
  comments: [] as CommentData[], // Replace '[]' with the appropriate type
  filteredComments: [] as CommentData[],
};

// CREATE NEW COMMENT
export const createComment = createAsyncThunk(
  "comment/create-new-comment",
  async (CommentInfo: CreateCommentFormData, thunkAPI) => {
    try {
      const response = await commentServices.createComment(CommentInfo);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  GET COMMENTS FOR A SPECIFIC POST
export const getCommentsForAPost = createAsyncThunk(
  "comment/getallpostcomments",
  async (postId: string, thunkAPI) => {
    try {
      const response = await commentServices.getCommentsForAPost(postId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  CREATE NESTED COMMENTS
export const createNestedComments = createAsyncThunk(
  "comment/create-new-comment/nexted-comment",
  async (CommentInfo: CreateNestedCommentFormData, thunkAPI) => {
    try {
      const response = await commentServices.createNestedComments(CommentInfo);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//  REACT TO COMMENTS
export const reactToComment = createAsyncThunk(
  "comment/react-to-comment",
  async (payload: { emoji: string; commentId: string }, thunkAPI) => {
    try {
      const { emoji, commentId } = payload;
      const response = await commentServices.reactToComment(emoji, commentId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// EDIT COMMENT BY THE COMMENT WRITTER OR AN ADMIN
export const editMyComment = createAsyncThunk(
  "comment/edit-comment",
  async (
    payload: { CommentInfo: EditCommentFormData; commentId: string },
    thunkAPI
  ) => {
    try {
      const { CommentInfo, commentId } = payload;
      const response = await commentServices.editMyComment(
        CommentInfo,
        commentId
      );
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE COMMENT BY THE COMMENT WRITTER OR AN ADMIN
export const deleteMyComment = createAsyncThunk(
  "comment/delete-comment",
  async (commentId: string, thunkAPI) => {
    try {
      const response = await commentServices.deleteMyComment(commentId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// REPORT A USER FROM COMMENT
export const reportUserComment = createAsyncThunk(
  "comment/report-user-comment",
  async (commentId: string, thunkAPI) => {
    try {
      const response = await commentServices.reportUserComment(commentId);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL COMMENTS TO ADMIN DASHBOARD
export const fetchAllComments = createAsyncThunk(
  "comment/getallcomments",
  async (_, thunkAPI) => {
    try {
      const response = await commentServices.fetchAllComments();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ************* SLICES ************* //
// ************* SLICES ************* //
// ************* SLICES ************* //
const commentSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    RESET(state) {
      state.isErrorComment = false;
      state.isSuccessComment = false;
      state.isLoadingComment = false;
      state.message = "";
    },

    FILTER_COMMENTS(state, action: PayloadAction<FilterCommentPayload>) {
      const { comments, searchWord } = action.payload;
      const filterr = comments.filter(
        (comment) =>
          comment.commenterName
            .toLowerCase()
            .includes(searchWord.toLowerCase()) ||
          comment.content.toLowerCase().includes(searchWord.toLowerCase()) ||
          comment.edited
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase())
      );
      state.filteredComments = filterr;
    },
  },

  // *********** ************ //
  extraReducers: (builder) => {
    builder
      //  CREATE NEW COMMENT
      .addCase(createComment.pending, (state) => {
        state.isLoadingComment = true;
        state.isLoadingCreate_Comment = true;
      })
      .addCase(createComment.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.isLoadingCreate_Comment = false;
        state.message = action.payload.message;
        state.comment = action.payload.comment;
        state.comments = action.payload.comments;
      })
      .addCase(createComment.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingCreate_Comment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  GET COMMENTS FOR A SPECIFIC POST
      .addCase(getCommentsForAPost.pending, (state) => {
        state.isLoadingComment = true;
      })
      .addCase(getCommentsForAPost.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.comments = action.payload.comments;
      })
      .addCase(getCommentsForAPost.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingComment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  CREATE NESTED COMMENTS
      .addCase(createNestedComments.pending, () => {
        // state.isLoadingComment = true;
      })
      .addCase(createNestedComments.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.message = action.payload.message;
        state.comments = action.payload.allComments;
        toast.success("");
      })
      .addCase(createNestedComments.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingComment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  REACT TO COMMENTS
      .addCase(reactToComment.pending, () => {
        // state.isLoadingReact_to_Comment = true;
        // state.isLoadingComment = true;
      })
      .addCase(reactToComment.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        // state.isLoadingReact_to_Comment = false;
        // state.isLoadingComment = false;
        // state.comment = action.payload.comment;
        // state.comments = action.payload.allComments;
        state.message = action.payload.message;
        // toast.success(action.payload.message);
      })
      .addCase(reactToComment.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingReact_to_Comment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  EDIT MY COMMENT
      .addCase(editMyComment.pending, (state) => {
        state.isLoadingEdit_Comment = true;
      })
      .addCase(editMyComment.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingEdit_Comment = false;
        state.comment = action.payload.comment;
        state.comments = action.payload.comments;
        state.message = action.payload.message;
      })
      .addCase(editMyComment.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingEdit_Comment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // DELETE COMMENT BY THE COMMENT WRITTER OR AN ADMIN
      .addCase(deleteMyComment.pending, (state) => {
        state.isLoadingComment = true;
      })
      .addCase(deleteMyComment.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.comments = action.payload.otherComments;
        state.message = action.payload.message;
      })
      .addCase(deleteMyComment.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingComment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // REPORT A USER FROM COMMENT
      .addCase(reportUserComment.pending, () => {
        // state.isLoadingComment = true;
      })
      .addCase(reportUserComment.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(reportUserComment.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingComment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // GET ALL COMMENTS TO ADMIN DASHBOARD
      .addCase(fetchAllComments.pending, (state) => {
        state.isLoadingComment = true;
        state.isSuccessComment = false;
      })
      .addCase(fetchAllComments.fulfilled, (state, action) => {
        state.isSuccessComment = true;
        state.isLoadingComment = false;
        state.comments = action.payload.comments;
        state.message = action.payload.message;
      })
      .addCase(fetchAllComments.rejected, (state, action) => {
        state.isErrorComment = true;
        state.isLoadingComment = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      });
  },
});

export const { RESET, FILTER_COMMENTS } = commentSlice.actions;

export default commentSlice.reducer;
