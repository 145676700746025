import { createSlice } from "@reduxjs/toolkit";

interface ThemeState {
  themeValue: "light" | "dark";
}

// Function to check if the user's browser mode is dark
// const isDarkModeEnabled = () => {
//   if (
//     window.matchMedia &&
//     window.matchMedia("(prefers-color-scheme: dark)").matches
//   ) {
//     return true;
//   }
//   return false;
// };

const initialState: ThemeState = {
  themeValue: "light",
  // themeValue: isDarkModeEnabled() ? "dark" : "light",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    CHANGE_THEME: (state) => {
      state.themeValue = state.themeValue === "light" ? "dark" : "light";
    },
  },
});

export const { CHANGE_THEME } = themeSlice.actions;

export default themeSlice.reducer;
