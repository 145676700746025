// Import the configured Axios instance
import axios from "../../../axios";
import { emailListFormData } from "./emailSubscribeInterface";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const MAILING_lISTS = `${backendUrl}/api/email-sub`;

// SUBSCRIBE TO EMAIL
const subscribeToMailList = async (data: emailListFormData) => {
  const response = await axios.post(
    MAILING_lISTS + "/subscribe-to-mail-list",
    data
  );
  return response.data;
};

// CONFIRM ACCOUNT FROM EMAIL LINK
const verifyAccountFromGmail = async (token: string) => {
  const response = await axios.patch(
    MAILING_lISTS + "/" + token + "/verifyuseraccount"
  );
  return response.data;
};

//  VIEW ALL THE SUBSCRIBE LIST
const viewSubscribeList = async () => {
  const response = await axios.get(
    MAILING_lISTS + "/view-all-subscribe-emails"
  );
  return response.data;
};

// CHANGE STATUS OF THE EMAIL LISTS
const changeStatus = async (id: string) => {
  const response = await axios.patch(
    MAILING_lISTS + "/" + id + "/change-email-active-status"
  );
  return response.data;
};

// DELETE OR UNSUBSCRIBE FROM THE MAIL LIST
const unSubscribeFromMailList = async () => {
  const response = await axios.delete(
    MAILING_lISTS + "/unsubscribe-from-mail-list"
  );
  return response.data;
};

//  ***********  EXPORTING ***********  //
const emailSubscribeService = {
  subscribeToMailList,
  verifyAccountFromGmail,
  viewSubscribeList,
  changeStatus,
  unSubscribeFromMailList,
};

export default emailSubscribeService;
