"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { emailListFormData, emailListsData } from "./emailSubscribeInterface";

import { toast } from "react-toastify";
import { CustomError } from "../comment/commentInterface";
import emailSubscribeService from "./emailSubscribeService";

const initialState = {
  isErrorEmailSub: false,
  isSuccessEmailSub: false,
  isLoadingEmailSub: false,
  message: "" as string,
  emailLists: [] as emailListsData[]
};

// SUBSCRIBE TO EMAIL
export const SubscribeToMailList = createAsyncThunk(
  "emailsub/subscribe",
  async (data: emailListFormData, thunkAPI) => {
    try {
      const response = await emailSubscribeService.subscribeToMailList(data);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SEND VERIFICATION EMAIL
export const verifyAccountFromGmail = createAsyncThunk(
  "emailsub/verify-account",
  async (payload: { token: string }, thunkAPI) => {
    try {
      const { token } = payload;
      const response = await emailSubscribeService.verifyAccountFromGmail(
        token
      );
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  VIEW ALL THE SUBSCRIBE LIST
export const ViewSubscribeList = createAsyncThunk(
  "emailsub/view-sub-list",
  async (_, thunkAPI) => {
    try {
      const response = await emailSubscribeService.viewSubscribeList();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// CHANGE STATUS OF THE EMAIL LISTS
export const ChangeStatus = createAsyncThunk(
  "emailsub/change-status",
  async (id: string , thunkAPI) => {
    try {
      const response = await emailSubscribeService.changeStatus(id);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE OR UNSUBSCRIBE FROM THE MAIL LIST
export const UnSubscribeFromMailList = createAsyncThunk(
  "emailsub/unsubscribe",
  async (_, thunkAPI) => {
    try {
      const response = await emailSubscribeService.unSubscribeFromMailList();
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ************* SLICES ************* //
// ************* SLICES ************* //
// ************* SLICES ************* //
const emailSubscribeSlice = createSlice({
  name: "emailSubscribe",
  initialState,
  reducers: {
    RESET(state) {
      state.isErrorEmailSub = false;
      state.isSuccessEmailSub = false;
      state.isLoadingEmailSub = false;
      state.message = "";
    },
  },

  // *********** ************ //
  extraReducers: (builder) => {
    builder
      // SUBSCRIBE TO EMAIL
      .addCase(SubscribeToMailList.pending, (state) => {
        state.isLoadingEmailSub = true;
      })
      .addCase(SubscribeToMailList.fulfilled, (state, action) => {
        state.isSuccessEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(SubscribeToMailList.rejected, (state, action) => {
        state.isErrorEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // SEND VERIFICATION EMAIL
      .addCase(verifyAccountFromGmail.pending, (state) => {
        state.isLoadingEmailSub = true;
      })
      .addCase(verifyAccountFromGmail.fulfilled, (state, action) => {
        state.isSuccessEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(verifyAccountFromGmail.rejected, (state, action) => {
        state.isErrorEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      //  VIEW ALL THE SUBSCRIBE LIST
      .addCase(ViewSubscribeList.pending, (state) => {
        state.isLoadingEmailSub = true;
      })
      .addCase(ViewSubscribeList.fulfilled, (state, action) => {
        state.isSuccessEmailSub = true;
        state.isLoadingEmailSub = false;
        state.emailLists = action.payload.emails;
      })
      .addCase(ViewSubscribeList.rejected, (state, action) => {
        state.isErrorEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // CHANGE STATUS OF THE EMAIL LISTS
      .addCase(ChangeStatus.pending, (state) => {
        state.isLoadingEmailSub = true;
      })
      .addCase(ChangeStatus.fulfilled, (state, action) => {
        state.isSuccessEmailSub = true;
        state.isLoadingEmailSub = false;
        state.emailLists = action.payload.updatedEmail;
        toast.success(action.payload.message);
      })
      .addCase(ChangeStatus.rejected, (state, action) => {
        state.isErrorEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      })

      // CHANGE STATUS OF THE EMAIL LISTS
      .addCase(UnSubscribeFromMailList.pending, (state) => {
        state.isLoadingEmailSub = true;
      })
      .addCase(UnSubscribeFromMailList.fulfilled, (state, action) => {
        state.isSuccessEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(UnSubscribeFromMailList.rejected, (state, action) => {
        state.isErrorEmailSub = true;
        state.isLoadingEmailSub = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      });
  },
});

export const { RESET } = emailSubscribeSlice.actions;

export default emailSubscribeSlice.reducer;
