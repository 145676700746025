"use client";
import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import emailServices, { emailData } from "./sendEmailService";
import { CustomError } from "../auth/authInterfaces";

const initialState = {
  isErrorEmail: false,
  isSuccessEmail: false,
  isLoadingEmail: false,
  message: "" as string,
};

// SENT AUTOMATED MAIL
export const SendAutomatedEmail = createAsyncThunk(
  "email/send-automated-email",
  async (emailData: emailData, thunkAPI) => {
    try {
      const response = await emailServices.sendAutomatedEmail(emailData);
      return response;
    } catch (error: unknown) {
      const err = error as CustomError;
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ************* SLICES ************* //
// ************* SLICES ************* //
// ************* SLICES ************* //
const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    RESET(state) {
      state.isErrorEmail = false;
      state.isSuccessEmail = false;
      state.isLoadingEmail = false;
      state.message = "";
    },
  },

  // *********** ************ //
  extraReducers: (builder) => {
    builder
      // SENT AUTOMATED MAIL
      .addCase(SendAutomatedEmail.pending, (state) => {
        state.isLoadingEmail = true;
      })
      .addCase(SendAutomatedEmail.fulfilled, (state, action) => {
        state.isSuccessEmail = true;
        state.isLoadingEmail = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(SendAutomatedEmail.rejected, (state, action) => {
        state.isErrorEmail = true;
        state.isLoadingEmail = false;
        state.message = action.payload as string;
        toast.error(action.payload as string);
      });
  },
});

export const { RESET } = emailSlice.actions;

export default emailSlice.reducer;
