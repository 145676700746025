import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./features/theme/themeSlice";
import authReducer from "./features/auth/authSlice";
import postReducer from "./features/post/postSlice";
import commentReducer from "./features/comment/commentSlice";
import emailReducer from "./features/email/sendEmailSlice";
import emailSubscribeReducer from "./features/emailSubscribe/emailSubscribeSlice";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    post: postReducer,
    comment: commentReducer,
    email: emailReducer,
    emailSubscribe: emailSubscribeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
